<template>
 <div>
  <sdPageHeader title="Afzender">
  </sdPageHeader>
  <Main v-if="errorSender">
   <sdCards title="">
    <a-result status="warning" style="padding: 0"
              title="Er is een fout opgetreden bij het ophalen van de gegevens.">
     <template #extra>
      <Button class="ant-btn ant-btn-primary" size="large" type="primary" @click="$router.replace('/sender')">
       Naar afzenders
      </Button>
     </template>
    </a-result>
   </sdCards>
  </Main>
  <Main v-if="!errorSender">
   <a-row :gutter="15" type="flex">
    <a-col :sm="18" :xs="24" style="display: flex;flex-flow: column;">
     <Upload>
      <div class="user-info-form">
       <BasicFormWrapper class="mb-25">
        <sdCards title="Algemeen">
         <a-skeleton v-if="loadingSender" active/>
         <a-form v-else :model="form" :rules="rules1" layout="vertical" @finish="UpdateSender1">
          <a-row :gutter="30">
           <a-col :sm="24" :xs="24" class="mb-25">
            <figure class="photo-upload align-center-v">
             <img
              v-if="form.imageFileURL && form.imageFileURL !== ''"
              :src="form.imageFileURL"
              alt=""
              style="max-width: 120px"
             />
             <img
              v-else
              :src="require('@/static/img/avatar/profileImage.png')"
              alt=""
              style="max-width: 120px"
             />
             <figcaption>
              <a-upload
               :action="`${fileUrl}senders/${form.id}/file`"
               :disabled="!$can('update', 'sender')"
               :headers="fileUploadHeaders"
               :multiple="false"
               name="file"
               @change="handleUpload">
               <a class="btn-upload" to="#">
                <sdFeatherIcons size="16" type="camera"/>
               </a>
              </a-upload>
             </figcaption>
            </figure>
           </a-col>
           <a-col :sm="12" :xs="24" class="mb-25">
            <a-form-item label="Naam" name="name">
             <a-input v-model:value="form.name" :disabled="!$can('update', 'sender')" placeholder=""/>
            </a-form-item>
           </a-col>
           <a-col :sm="12" :xs="24" class="mb-25">
            <a-form-item label="Afzendernaam" name="senderName">
             <a-input v-model:value="form.senderName" :disabled="!$can('update', 'sender')" placeholder=""/>
            </a-form-item>
           </a-col>
           <a-col :sm="12" :xs="24" class="mb-25">
            <a-form-item label="E-mailadres" name="email">
             <a-input v-model:value="form.email" :disabled="!$can('update', 'sender')" placeholder=""/>
            </a-form-item>
           </a-col>
           <a-col :sm="12" :xs="24" class="mb-25">
            <a-form-item label="Afzender e-mailadres" name="senderEmail">
             <a-input v-model:value="form.senderEmail" :disabled="!$can('update', 'sender')" placeholder=""/>
            </a-form-item>
           </a-col>
           <a-col :sm="12" :xs="24" class="mb-25">
            <a-form-item label="Afzendernaam SMS" name="smsOriginator">
             <a-input v-model:value="form.smsOriginator" :disabled="!$can('update', 'sender')" placeholder=""/>
            </a-form-item>
           </a-col>
           <a-col :sm="12" :xs="24" class="mb-25">
            <a-form-item label="Telefoonnummer" name="phoneNumber">
             <a-input v-model:value="form.phoneNumber" :disabled="!$can('update', 'sender')"
                      onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                      onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                      placeholder=""
                      type="number"/>
            </a-form-item>
           </a-col>
          </a-row>
          <a-row>
           <a-col :sm="12" :xs="24">
            <div class="sDash_form-action">
             <sdButton v-if="$can('update', 'sender')" :disabled="loadingPatchSender"
                       :loading="loadingPatchSender && (clickedButton === 1)" class="btn-signin"
                       type="primary"> Opslaan
             </sdButton>
            </div>
           </a-col>
          </a-row>
         </a-form>
        </sdCards>
       </BasicFormWrapper>
      </div>
     </Upload>
    </a-col>
    <a-col :sm="6" :xs="24" style="display: flex;flex-flow: column;">
     <sdCards :style="form.senderEmailVerified?'flex: 1 1 auto;':''" title="Informatie">
      <a-skeleton v-if="loadingSender" active/>
      <a-row v-else :gutter="30">
       <a-col :xs="24">
        <InfoWrapper>
         <div class="info-title">Email geverifieerd</div>
         <div class="info-text">{{ form.senderEmailVerified ? 'Geverifieerd' : 'Niet geverifieerd' }}
         </div>
        </InfoWrapper>
       </a-col>
      </a-row>
     </sdCards>
    </a-col>
   </a-row>
   <div v-if="!form.senderEmailVerified" class="user-info-form">
    <sdCards style="flex: 1 1 auto;" title="DNS Records">
     <a-skeleton v-if="loadingDns" active/>
     <a-row v-else :gutter="30">
      <a-col :xs="24">
       <InfoWrapper v-if="!form.senderEmailVerified">
        <div class="mb-5">
         <div class="info-text">
          Graag de onderstaande DNS records toevoegen aan het domein. Nadat de validatie succesvol is afgerond, zal deze
          informatie verdwijnen.
         </div>
        </div>
        <div v-for="(item, index) in dnsData" :key="index" class="mb-5">
         <div class="info-text" style="color:#000000">
          <small>type: {{ item.type }}</small>
         </div>
         <div class="info-text" style="color:#000000">
          <small>name: {{ item.name }}</small>
         </div>
         <div class="info-text" style="color:#000000">
          <small>value: {{ item.value }}</small>
         </div>
        </div>
       </InfoWrapper>
      </a-col>
     </a-row>
    </sdCards>
   </div>
   <div class="user-info-form">
    <BasicFormWrapper class="mb-25">
     <sdCards title="Adres">
      <a-skeleton v-if="loadingSender" active/>
      <a-form v-else :model="form" :rules="rules2" layout="vertical" @finish="UpdateSender2">
       <a-row :gutter="30">
        <a-col :sm="11" :xs="24" class="mb-25">
         <a-form-item label="Postcode" name="postalCode">
          <a-input v-model:value="form.postalCode" :disabled="!$can('update', 'sender')" placeholder=""/>
         </a-form-item>
        </a-col>
        <a-col :sm="5" :xs="24" class="mb-25">
         <a-form-item label="Huisnummer" name="houseNumber">
          <a-input v-model:value="form.houseNumber" :disabled="!$can('update', 'sender')"
                   onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                   onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                   placeholder=""
                   type="number"/>
         </a-form-item>
        </a-col>
        <a-col :sm="5" :xs="24" class="mb-25">
         <a-form-item v-if="houseNumberAdditionsOptions.length > 0" label="Toevoeging"
                      name="houseNumberAddition"
         >
          <a-select v-model:value="form.houseNumberAddition" :allowClear="true" :disabled="!$can('update', 'sender')"
                    class="sDash_fullwidth-select"
                    size="large">
           <a-select-option v-for="(item,index) in houseNumberAdditionsOptions"
                            :key="index"
                            :value="item.value" name="energyAllowSingleFuelElectricity">
            {{ item.key }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col :sm="3" :xs="24" class="mb-25">
         <a-form-item label="&nbsp;">
          <a-button v-if="$can('update', 'sender')" :disabled="disabledGlobalSearchButton"
                    :loading="globalSearchLoading" class="btn-signin w-100"
                    size="large"
                    type="primary" @click="globalSearch">
           Zoeken
          </a-button>
         </a-form-item>
        </a-col>
       </a-row>
       <a-row :gutter="30">
        <a-col :sm="10" :xs="24" class="mb-25">
         <a-form-item label="Straat" name="street">
          <a-input v-model:value="form.street" :disabled="!$can('update', 'sender')" placeholder=""/>
         </a-form-item>
        </a-col>
        <a-col :sm="7" :xs="24" class="mb-25">
         <a-form-item label="Plaats" name="city">
          <a-input v-model:value="form.city" :disabled="!$can('update', 'sender')" placeholder=""/>
         </a-form-item>
        </a-col>
        <a-col :sm="7" :xs="24" class="mb-25">
         <a-form-item label="Land" name="country">
          <a-select v-model:value="form.country" :allowClear="true" :disabled="!$can('update', 'sender')"
                    class="sDash_fullwidth-select"
                    name="country"
                    size="large">
           <a-select-option v-for="(item,index) in countryOptions" :key="index" :value="item.value">
            {{ item.key }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
       </a-row>
       <a-row>
        <a-col :sm="12" :xs="24">
         <div class="sDash_form-action">
          <sdButton v-if="$can('update', 'sender')" :disabled="loadingPatchSender"
                    :loading="loadingPatchSender && (clickedButton === 2)" class="btn-signin"
                    type="primary"> Opslaan
          </sdButton>
         </div>
        </a-col>
       </a-row>
      </a-form>
     </sdCards>
    </BasicFormWrapper>
   </div>
   <div class="user-info-form">
    <BasicFormWrapper class="mb-25">
     <sdCards title="Kleuren">
      <a-form :model="form" :rules="rules3" layout="vertical" @finish="UpdateSender3">
       <a-row :gutter="30" class="mb-25">
        <a-col :span="12">
         <a-form-item label="Primaire kleur" name="primaryColor">
          <a-input
           v-model:value="form.primaryColor"
           :disabled="!$can('update', 'sender')"
           :style="{ height: '55px' }"
           name="color"
           type="color"
          />
         </a-form-item>
        </a-col>
        <a-col :span="12">
         <a-form-item label="Secundaire kleur" name="secondaryColor">
          <a-input
           v-model:value="form.secondaryColor"
           :disabled="!$can('update', 'sender')"
           :style="{ height: '55px' }"
           name="color"
           type="color"
          />
         </a-form-item>
        </a-col>
       </a-row>
       <a-row>
        <a-col :sm="12" :xs="24">
         <div class="sDash_form-action">
          <sdButton v-if="$can('update', 'sender')" :disabled="loadingPatchSender"
                    :loading="loadingPatchSender && (clickedButton === 3)" class="btn-signin"
                    type="primary"> Opslaan
          </sdButton>
         </div>
        </a-col>
       </a-row>
      </a-form>
     </sdCards>
    </BasicFormWrapper>
   </div>
   <sdCards>
    <a-row>
     <a-col :sm="12" :xs="24">
      <div class="sDash_form-action">
       <sdButton class="btn-signin" html-type="submit" type="light" @click="$router.go(-1)">
        Terug
       </sdButton>
      </div>
     </a-col>
    </a-row>
   </sdCards>
  </Main>
 </div>
</template>

<script>
import {BasicFormWrapper, InfoWrapper, Main, Upload} from '../styled';
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {countryOptions} from "../../utility/enums";
import {phoneNumberValidator, postalCodeValidator} from "../../utility/validators";
import {getItem} from "../../utility/localStorageControl";
import {getApiURL} from "../../config/dataService/dataService";

export default defineComponent({
 name: 'SenderEdit',
 components: {
  BasicFormWrapper,
  Main,
  Upload,
  InfoWrapper,
 },
 data() {
  return {
   loading: false,
   debounce: null,
   selectedAddress: {
    postalCode: '',
    houseNumber: '',
   },
   fileUrl: getApiURL(),
  }
 },
 watch: {
  form: {
   handler(value) {
    if ((value.postalCode && value.houseNumber) && (this.selectedAddress.postalCode !== value.postalCode || this.selectedAddress.houseNumber !== value.houseNumber)) {
     this.selectedAddress.postalCode = value.postalCode;
     this.selectedAddress.houseNumber = value.houseNumber;
     clearTimeout(this.debounce)
     this.debounce = setTimeout(() => {
      this.$store.dispatch("houseNumberAdditions", {
       postalCode: this.form.postalCode,
       houseNumber: this.form.houseNumber,
       country: 'NL',
       type: 'ENERGY',
      });
     }, 600)
    }
   },
   deep: true,
  }
 },
 setup() {
  const {state, dispatch} = useStore();
  const {params} = useRoute();
  const clickedButton = ref(0);
  const form = reactive(computed(() => state.sender.sender));
  const errorSender = computed(() => state.sender.errorSender);
  const loadingPatchSender = computed(() => state.team.loadingPatchSender);
  const loadingSender = computed(() => state.team.loadingSender);
  const loadingDns = computed(() => state.team.loadingSender);
  const houseNumberAdditionsOptions = reactive(computed(() => state.globalSearch.houseNumberAdditions));
  const globalSearchData = reactive(computed(() => state.globalSearch.data));
  const globalSearchLoading = reactive(computed(() => state.globalSearch.loadingSearch));
  const dnsData = reactive(computed(() => state.dns.dns));
  const disabledGlobalSearchButton = reactive(computed(() => state.globalSearch.disabledGlobalSearchButton));
  const rules1 = {
   senderName: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   senderEmail: [
    {
     required: true,
     type: "email",
     message: "Vul een valide e-mailadres in",
    },
   ],
   phoneNumber: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: phoneNumberValidator,
    },
   ],
   email: [
    {
     required: true,
     type: "email",
     message: "Vul een valide e-mailadres in",
    },
   ],
   smsOriginator: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };
  const rules2 = {
   city: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   country: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   houseNumber: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   postalCode: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: postalCodeValidator,
    },
   ],
   street: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };
  const rules3 = {
   primaryColor: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   secondaryColor: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const handleUpload = async (info) => {
   if (info.file.status === 'done') {
    await dispatch('fileUploadedSuccess');
    await dispatch('getSender', params.id);
   } else if (info.file.status === 'error') {
    await dispatch('fileUploadedError');
   }
  };

  const UpdateSender1 = () => {
   clickedButton.value = 1;
   dispatch('patchSender', {
    id: params.id,
    name: form.value.name,
    senderName: form.value.senderName,
    senderEmail: form.value.senderEmail,
    email: form.value.email,
    phoneNumber: form.value.phoneNumber,
    smsOriginator: form.value.smsOriginator,
   });
  };
  const UpdateSender2 = () => {
   clickedButton.value = 2;
   dispatch('patchSender', {
    id: params.id,
    postalCode: form.value.postalCode,
    houseNumber: form.value.houseNumber,
    houseNumberAddition: form.value.houseNumberAddition,
    city: form.value.city,
    country: form.value.country,
    street: form.value.street,
   });
  };
  const UpdateSender3 = () => {
   clickedButton.value = 13;
   dispatch('patchSender', {
    id: params.id,
    primaryColor: form.value.primaryColor,
    secondaryColor: form.value.secondaryColor,
   });
  };

  const setAddress = (data) => {
   form.value.street = data.street;
   form.value.city = data.city;
   form.value.country = data.country;
  };

  const globalSearch = () => {
   dispatch('searchAddress', {
    value: {
     postalCode: form.value.postalCode,
     houseNumber: form.value.houseNumber,
     houseNumberAddition: form.value.houseNumberAddition && form.value.houseNumberAddition !== '' ? form.value.houseNumberAddition : null,
     country: 'NL',
     type: 'ENERGY',
    },
    setAddress: setAddress,
   });
  };

  const fileUploadHeaders = {
   Authorization: `Bearer ${getItem('access_token')}`
  };

  onMounted(async () => {
   await dispatch('getSender', params.id);
   if (!form.value.senderEmailVerified) {
    await dispatch('getDns', params.id);
   }
  });

  return {
   fileUploadHeaders,
   form,
   rules1,
   rules2,
   rules3,
   errorSender,
   UpdateSender1,
   UpdateSender2,
   UpdateSender3,
   loadingPatchSender,
   clickedButton,
   handleUpload,
   countryOptions,
   houseNumberAdditionsOptions,
   globalSearchData,
   globalSearchLoading,
   disabledGlobalSearchButton,
   globalSearch,
   loadingSender,
   dnsData,
   loadingDns,
  };
 },
});
</script>
